import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

import { loginUser } from '../../store/auth/login/actions';

import Error from "../../components/alerts/Error";
import { darklogo } from "../../assets";

const Signup = ({ history }) => {
	// local state
	const [loginData, setLoginData] = useState({});
	const [showPassword, setShowPassword] = useState(false);

	// redux
	const dispatch = useDispatch();
	const { loading, error } = useSelector((state => state.Login));

	// Handle Show and Hide Password
	const handleShowPassword = () => {
		setShowPassword(!showPassword);
	};

	// handle input change
	const handleChange = (e) => {
		const newLoginData = { ...loginData };
		newLoginData[e.target.name] = e.target.value;
		setLoginData(newLoginData);
	};

	// get form
	const form = document.querySelector("form");

	// submit handler function
	const submitHandler = (e) => {
		e.preventDefault();
		if (!form.checkValidity()) {
			form.classList.add("was-validated");
		} else {
			form.classList.remove("was-validated");
			dispatch(loginUser(loginData, history));
		}
	};

	return (
		<div className="d-flex align-items-center bg-primary image-backgroud" style={{ height: "100vh" }}>
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-md-5">
						<div className="authincation-content">
							<div className="row no-gutters">
								<div className="col-xl-12">
									<div className="auth-form">
										<div className="text-center mx-auto logo-section">
											<img
												src={darklogo}
												alt="about"
												className="img-fluid"
											/>
										</div>
										<hr></hr>
										<div className="form-title mb-4 text-center">
											<p>Please Create an Account</p>
										</div>
										<form noValidate onSubmit={(e) => submitHandler(e)}>
											{error && <Error error={error} />}
											<div className="form-group">
												<label className="mb-1">
													<strong>Full Names</strong>
												</label>
												<input
													type="text"
													className="form-control"
													placeholder="Joe Doe"
													name="fullname"
													required
													onChange={handleChange}
												/>
												<span className="invalid-feedback">Invalid Email</span>
											</div>
											<div className="form-group">
												<label className="mb-1">
													<strong>Email</strong>
												</label>
												<input
													type="email"
													className="form-control"
													placeholder="hello@example.com"
													name="email"
													required
													onChange={handleChange}
												/>
												<span className="invalid-feedback">Invalid Email</span>
											</div>
											<div className="form-group mt-4 position-relative">
												<label className="mb-1">
													<strong>Password</strong>
												</label>
												<input
													type={showPassword ? "text" : "password"}
													required
													className="form-control"
													placeholder="password"
													name="password"
													onChange={handleChange}
												/>
												<div
													style={{
														position: "absolute",
														right: 30,
														marginTop: "-35px",
													}}
												>
													{showPassword ? (
														<AiOutlineEyeInvisible
															size={20}
															className="text-muted"
															style={{ cursor: "pointer" }}
															onClick={handleShowPassword}
														/>
													) : (
														<AiOutlineEye
															size={20}
															className="text-muted"
															style={{ cursor: "pointer" }}
															onClick={handleShowPassword}
														/>
													)}
												</div>
												<span className="invalid-feedback">Password can't be empty</span>
											</div>
											<div className="mt-3">
												<button
													className="btn btn-primary w-100 waves-effect waves-light"
													type="submit"
													disabled={loading}
												>
													{loading ? (
														<div
															className="spinner-border spinner-border-sm text-light"
															role="status"
														>
															<span className="sr-only">Loading...</span>
														</div>
													) : (
														"Sign Up"
													)}
												</button>
											</div>
										</form>
										<div className="new-account mt-3 text-center">
											<p>
												Have an account?{" "}
												<Link className="text-primary" to="/login">
													Login
												</Link>
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Signup;

import React, { useState } from 'react';

// Layout
import Nav from "./nav";
import Footer from "./Footer";

const Index = ({ children, width }) => {
	console.log("width", width);
	const [showProfile, setShowProfile] = useState(false);
	let path = window.location.pathname;
	path = path.split("/");
	path = path[path.length - 1];
	let pagePath = path.split("-").includes("page");

	return (
		<div
			id={`${!pagePath ? "main-wrapper" : ""}`}
			className={`${!pagePath ? "show" : "mh100vh"} ${!path ? "right-profile" : ""
				}`}
		>
			{!pagePath && (
				<Nav
					showProfileSideBar={true}
					showProfile={() => setShowProfile(!showProfile)}
				/>
			)}
			<div
				className={`  ${!pagePath ? "content-body" : ""}`}

			>
				<div className={`${!pagePath ? "container-fluid mb-5" : ""}`}>
					{children}
				</div>
			</div>
			{!pagePath && <Footer />}
		</div>
	);
};

export default Index;
import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import SetPassword from "./auth/setpassword/reducer";
import currentUser from "./users/currentUser/reducer";
import customers from "./users/customers/reducer";
import allTags from "./tags/allTags/reducer";
import customerTags from "./tags/customerTags/reducer";
import customerTagInfo from "./tags/customerTagInfo/reducer";
import newCustomerTag from "./tags/newCustomerTag/reducer";
import modifyCustomerTag from "./tags/modifyCustomerTag/reducer";
import removeCustomerTag from "./tags/removeCustomerTag/reducer";
import newAvatarUpload from "./uploads/newAvatarUpload/reducer";
import customerTagsStats from "./tags/customerTagsStats/reducer";
import analytics from "./dashboard/analytics/reducer"

// orders
import allOrders from "./dashboard/allOrders/reducer"
import myOrders from "./orders/reducer"

//contacts
import contacts from "./contacts/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  ForgetPassword,
  SetPassword,
  contacts,
  currentUser,
  customers,
  allTags,
  analytics,
  allOrders,
  myOrders,
  customerTags,
  customerTagInfo,
  newCustomerTag,
  modifyCustomerTag,
  removeCustomerTag,
  newAvatarUpload,
  customerTagsStats,
});

export default rootReducer;

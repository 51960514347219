import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import Error from "../../components/alerts/Error";
import Success from "../../components/alerts/Success";
import { darklogo } from "../../assets";
import { userForgetPassword } from "../../store/actions";

// import "../../css/style.css";

const ForgotPassword = ({ history }) => {
  // local state
  const [data, setData] = useState({});

  // redux
  const dispatch = useDispatch();
  const { loading, forgetError, forgetSuccessMsg } = useSelector((state => state.ForgetPassword));

  // handle input change
  const handleChange = (e) => {
    const newData = { ...data };
    newData[e.target.name] = e.target.value;
    setData(newData);
  };

  // get form
  const form = document.querySelector("form");

  // submit handler function
  const submitHandler = (e) => {
    e.preventDefault();
    if (!form.checkValidity()) {
      form.classList.add("was-validated");
    } else {
      form.classList.remove("was-validated");
      dispatch(userForgetPassword(data, history));
    }
  };

  return (
    <div className="d-flex align-items-center bg-primary image-backgroud" style={{ height: "100vh" }}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-5">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="text-center mx-auto logo-section">
                      <img
                        src={darklogo}
                        alt="about"
                        className="img-fluid"
                      />
                    </div>
                    <hr></hr>
                    <div className="form-title mb-4 text-center">
                      <p className="lh-base">Please enter your email  to <br /> recieve a reset link</p>
                    </div>
                    <form noValidate onSubmit={(e) => submitHandler(e)}>
                      {forgetError && <Error error={forgetError} />}
                      {forgetSuccessMsg && <Success message={forgetSuccessMsg} />}
                      <div className="form-group">
                        <label className="mb-1">
                          <strong>Email</strong>
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="hello@example.com"
                          name="email"
                          required
                          onChange={handleChange}
                        />
                        <span className="invalid-feedback">Invalid Email</span>
                      </div>
                      <div className="mt-n2">
                        Remembered your password?{" "}
                        <Link to="/login">
                          login
                        </Link>
                      </div>
                      <div className="mt-4">
                        <button
                          className="btn btn-primary w-100 waves-effect waves-light"
                          type="submit"
                          disabled={loading}
                        >
                          {loading ? (
                            <div
                              className="spinner-border spinner-border-sm text-light"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          ) : (
                            "Send Link"
                          )}
                        </button>
                      </div>
                    </form>
                    <div className="new-account mt-3 text-center">
                      <p>
                        Don't have an account?{" "}
                        <Link className="text-primary" to="/signup">
                          Sign up
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;

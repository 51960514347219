import React, { useEffect } from "react";
import { Row, Col, CardBody, Card } from "reactstrap";

//Import Components
import LineChart from "./line-chart";
import LatestTransaction from "./latest-transaction";
import { useDispatch, useSelector } from "react-redux";
import { getAnalytics } from "../../store/actions";
import DotLoader from "../../components/dotLoader/DotLoader";

const Dashboard = () => {
  // redux
  const { analytics, loading } = useSelector(state => state.analytics);

  // dispatch
  const dispatch = useDispatch();

  // useEffect
  useEffect(() => {
    dispatch(getAnalytics());
  }, [dispatch]);

  return (
    <>
      <div className="page-content">
        <Row>
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18">Dashboard </h4>
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Welcome to Bizcotap Dashboard
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </Row>
        {false ?
          <div className="dashboard-spinner-styles">
            <div
              className="spinner-border spinner-border-sm text-light"
              role="status">
              <span className="text-center sr-only">Loading...</span>
            </div>
          </div> :
          <Row>
            <Col lg={3}>
              <Card>
                <CardBody>
                  <div className="d-flex align-items-start">
                    <div className="avatar-md font-size-20 me-3">
                      <span className="avatar-title bg-soft-primary text-primary dashboard-icon">
                        <i className="dripicons dripicons-shopping-bag"></i>
                      </span>
                    </div>
                    <div className="flex-1">
                      <div className="font-size-16 mt-2">Total Orders</div>
                      <h4 className="font-weight-bold mt-2">{!loading ? analytics?.total_orders : <DotLoader />}</h4>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <div className="d-flex align-items-start">
                    <div className="avatar-md font-size-20 me-3">
                      <span className="avatar-title bg-soft-primary text-primary dashboard-icon">
                        <i className="dripicons dripicons-wallet"></i>
                      </span>
                    </div>
                    <div className="flex-1">
                      <div className="font-size-16 mt-2">Total Revenues</div>
                      <h4 className="font-weight-bold mt-2">{!loading ? `$ ${analytics?.total_revenue}` : <DotLoader />}</h4>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <div className="d-flex align-items-start">
                    <div className="avatar-md font-size-20 me-3">
                      <span className="avatar-title bg-soft-primary text-primary dashboard-icon">
                        <i className="dripicons dripicons-user"></i>
                      </span>
                    </div>
                    <div className="flex-1">
                      <div className="font-size-16 mt-2">Total Users</div>
                      <h4 className="font-weight-bold mt-2">{!loading ? analytics?.users : <DotLoader />}</h4>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg={3}>
              <Card>
                <CardBody>
                  <div className="d-flex align-items-start">
                    <div className="avatar-md font-size-20 me-3">
                      <span className="avatar-title bg-soft-primary text-primary dashboard-icon">
                        <i className="dripicons dripicons-wallet"></i>
                      </span>
                    </div>
                    <div className="flex-1">
                      <div className="font-size-16 mt-2">Stock Value</div>
                      <h4 className="font-weight-bold mt-2">$ 2,300</h4>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <div className="d-flex align-items-start">
                    <div className="avatar-md font-size-20 me-3">
                      <span className="avatar-title bg-soft-primary text-primary dashboard-icon">
                      <i className="dripicons dripicons-wallet"></i>
                      </span>
                    </div>
                    <div className="flex-1">
                      <div className="font-size-16 mt-2">Trees Saved</div>
                      <h4 className="font-weight-bold mt-2">899</h4>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <div className="d-flex align-items-start">
                    <div className="avatar-md font-size-20 me-3">
                      <span className="avatar-title bg-soft-primary text-primary dashboard-icon">
                      <i className="dripicons dripicons-tags"></i>
                      </span>
                    </div>
                    <div className="flex-1">
                      <div className="font-size-16 mt-2">Total Tags</div>
                      <h4 className="font-weight-bold mt-2">{!loading ? analytics?.tags : <DotLoader />}</h4>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg={6}>
              <LineChart />
            </Col>
          </Row>}

        <Row>
          <LatestTransaction />
        </Row>
      </div>
    </>
  );
};

export default Dashboard;

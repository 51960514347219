import React from "react";
import { Container, Row, Col } from "reactstrap";

const Footer = () => {
	return (
		<footer className="footer text-center">
			<Container fluid={true}>
				<Row>
					<Col sm={12}>Copyright © {new Date().getFullYear()} Bizcotap. All rights reserved.</Col>
				</Row>
			</Container>
		</footer>
	);
};

export default Footer;

import { takeEvery, put, call } from "redux-saga/effects";

import { GET_ALL_ORDERS } from "./actionTypes";
import { getAllOrdersSuccess, getAllOrdersFail } from "./actions";
import { allOrdersEndpoint } from "../../../helpers/dashboard";


function* allOrders(action) {
  try {
    const response = yield call(allOrdersEndpoint);
    if (response?.ok === 1) {
    console.log("respo", response);
      yield put(getAllOrdersSuccess(response?.orders));
    } else {
      yield put(getAllOrdersFail(response?.message));
    }
  } catch (error) {
    console.log("error", error);
    yield put(getAllOrdersFail(error?.message || error));
  }
}

function* allOrdersSaga() {
  yield takeEvery(GET_ALL_ORDERS, allOrders);
}

export default allOrdersSaga;

/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Row, Col, CardBody, Card } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { allTags, removeCustomerTag } from "../../store/actions";
import Tags from "./Tags";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

const AllTags = (props) => {
  const [activePage, setActivePage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [search, setSearch] = React.useState("");

  let getItemsTimeout;

  const onGetTags = () => {
    if (getItemsTimeout) clearTimeout(getItemsTimeout);
    getItemsTimeout = setTimeout(() => {
      props.getTags({
        page: activePage,
        size: pageSize,
        search,
        mine: props?.showMine ? 1 : 0,
      });
    }, 400);
  };

  const onDeleteTag = (tagId) => {
    if (tagId) {
      props?.deleteTag(tagId);
    }
  };

  const onChangePageSize = (size = 10) => {
    setPageSize(size);
  };

  const onChangePagePrev = () => {
    if (Number(props?.allTags?.data?.current_page) - 1 > 0) {
      setActivePage(activePage - 1);
    }
  };

  const onChangePageNext = () => {
    if (activePage + 1 <= Number(props?.allTags?.data?.last_page)) {
      setActivePage(activePage + 1);
    }
  };

  const onSearch = (keyword = "") => {
    setSearch(keyword);
  };

  React.useEffect(() => {
    onGetTags();

    return () => {
      if (getItemsTimeout) clearTimeout(getItemsTimeout);
    };
  }, [activePage, pageSize, search, props?.showMine]);

  React.useEffect(() => {
    setActivePage(1);
  }, [pageSize]);

  React.useEffect(() => {
    if (props?.deleteTagState?.message) {
      onGetTags();
    }
  }, [props?.deleteTagState?.message]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18">
                {props?.showMine ? "My Tags" : "All Tags"}
              </h4>
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">Bizcotap / Tags</li>
                </ol>
              </div>
            </div>
          </div>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Tags
                  tagsState={props?.allTags}
                  onDeleteTag={onDeleteTag}
                  deleteTagState={props?.deleteTagState}
                  activePage={activePage}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  onChangePageSize={onChangePageSize}
                  onChangePagePrev={onChangePagePrev}
                  onChangePageNext={onChangePageNext}
                  onRefresh={onGetTags}
                  search={search}
                  onSearch={onSearch}
                  title={props?.showMine ? "My Tags" : "All Tags"}
                  currentUser={props?.currentUser}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

AllTags.propTypes = {
  layout: PropTypes.any,
  showMine: PropTypes.bool,
  currentUser: PropTypes.object,
  allTags: PropTypes.object,
  getTags: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
    currentUser: state.currentUser,
    allTags: state.allTags,
    deleteTagState: state.removeCustomerTag,
  };
};

export default connect(mapStateToProps, {
  getTags: allTags,
  deleteTag: removeCustomerTag,
})(AllTags);

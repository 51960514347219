import { takeEvery, put, call } from "redux-saga/effects";

// Login Redux States
import { CUSTOMER_TAGS_STATS } from "./actionTypes";
import { onCustomerTagsStatsFail, onCustomerTagsStatsSuccess } from "./actions";

import { getCustomerTagsStats } from "../../../helpers/tags";

function* customerTagsStats(action) {
  try {
    const response = yield call(
      getCustomerTagsStats,
      action?.payload?.timeline
    );
    if (response?.ok === 1) {
      yield put(onCustomerTagsStatsSuccess(response?.data));
    } else {
      yield put(onCustomerTagsStatsFail(response?.message));
    }
  } catch (error) {
    yield put(onCustomerTagsStatsFail(error?.message || error));
  }
}

function* customerTagsStatsSaga() {
  yield takeEvery(CUSTOMER_TAGS_STATS, customerTagsStats);
}

export default customerTagsStatsSaga;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  CardBody,
  Card,
  CardTitle,
  Button,
  ButtonGroup,
} from "reactstrap";
import { customerTagsStats } from "../../store/actions";
import { connect } from "react-redux";
import { TIMELINES, TIMELINE_WEEKLY } from "../../constants";
import RecentContacts from "./RecentContacts";

const OPTIONS = {
  chart: {
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    bar: {
      horizontal: !1,
      columnWidth: "45%",
      endingShape: "rounded",
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: true,
    width: 2,
    colors: ["transparent"],
  },
  colors: ["#194277", "#ff8f16"],
  yaxis: {
    title: {
      text: " (visited/saved)",
    },
  },
  grid: {
    borderColor: "#f1f1f1",
  },
  fill: {
    opacity: 1,
  },
};

const UserDashboard = (props) => {
  const [series, setSeries] = useState([]);
  const [timeline, setTimeline] = useState(TIMELINE_WEEKLY);

  const numTrees = Math.floor(props?.tagsStats?.data?.views?.total * 0.02);

  const onData = (data) => {
    if (data && data?.views) {
      const stats = [
        {
          name: "Visited My Profile",
          data: data?.views?.[`${timeline}`],
        },
        {
          name: "Saved My Contact",
          data: data?.downloads?.[`${timeline}`],
        },
      ];
      setSeries(stats);
    }
  };

  useEffect(() => {
    props.getTagsStats(timeline);
  }, [timeline]);

  useEffect(() => {
    onData(props?.tagsStats?.data);
  }, [props?.tagsStats]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18">Dashboard</h4>
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Welcome to Bizcotap Dashboard
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </Row>
        <Row>
          <Col lg={4}>
            <Row>
              <Col lg={6}>
                <Card>
                  <CardTitle className="card-stats">
                    <div className="statistics-title">Total Tags</div>
                  </CardTitle>
                  <CardBody>
                    <div className="d-flex align-items-start">
                      <div className="flex-1 py-4">
                        <h4 className="font-weight-bold mt-2">
                          {props?.tagsStats?.data?.tags?.total || 0}
                        </h4>
                      </div>
                    </div>
                    {/* <div className="mt-3">
                      <span className="badge badge-success r-30">
                        <span className="diff">
                          <b className="color-up"><i className="fa fa-caret-up"></i>
                            26%</b> last month</span>
                      </span>
                    </div> */}
                  </CardBody>
                </Card>
              </Col>
              <Col lg={6}>
                <Card>
                  <CardTitle className="card-stats">
                    <div className="statistics-title">Tree{numTrees > 1 ? "s" : ""} Saved</div>
                  </CardTitle>
                  <CardBody>
                    <div className="d-flex align-items-start">
                      <div className="flex-1 py-4">
                        <h4 className="font-weight-bold mt-2">
                          {numTrees}
                        </h4>
                      </div>
                    </div>
                    {/* <div className="mt-3">
                      <span className="badge badge-success r-30">
                        <span className="diff">
                          <b className="color-up"><i className="fa fa-caret-up"></i>
                            26%</b> last month</span>
                      </span>
                    </div> */}
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <Card>
                  <CardTitle className="card-stats">
                    <div className="statistics-title">Profile Visits</div>
                  </CardTitle>
                  <CardBody>
                    <div className="d-flex align-items-start">
                      <div className="flex-1 py-4">
                        <h4 className="font-weight-bold mt-2">
                          {props?.tagsStats?.data?.views?.total || 0}
                        </h4>
                      </div>
                    </div>
                    {/* <div className="mt-3">
                      <span className="badge badge-danger r-30">
                        <span className="diff">
                          <b className="color-up"><i className="fa fa-caret-up"></i>
                            26%</b> last month</span>
                      </span>
                    </div> */}
                  </CardBody>
                </Card>
              </Col>
              <Col lg={6}>
                <Card>
                  <CardTitle className="card-stats">
                    <div className="statistics-title">Saved My Contact</div>
                  </CardTitle>
                  <CardBody>
                    <div className="d-flex align-items-start">
                      <div className="flex-1 py-4">
                        <h4 className="font-weight-bold mt-2">
                          {props?.tagsStats?.data?.downloads?.total || 0}
                        </h4>
                      </div>
                    </div>
                    {/* <div className="mt-3">
                      <span className="badge badge-danger r-30">
                        <span className="diff">
                          <b className="color-up"><i className="fa fa-caret-up"></i>
                            26%</b> last month</span>
                      </span>
                    </div> */}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col lg={8}>
            <Card>
              <CardBody>
                <CardTitle className="mb-4 d-flex flex-column flex-md-row align-items-center justify-content-between">
                  <div className="pb-2">
                    <span>Networking Growth</span>
                  </div>
                  <ButtonGroup size="sm">
                    {TIMELINES.map((t) => (
                      <Button
                        active={timeline === t.value}
                        outline
                        color="primary"
                        onClick={() => setTimeline(t.value)}
                      >
                        {t.label}
                      </Button>
                    ))}
                  </ButtonGroup>
                </CardTitle>
                <ReactApexChart
                  options={OPTIONS}
                  series={series}
                  type="bar"
                  height={260}
                  className="apex-charts"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <div className="row">
          <div className="col-xl-12">
            <RecentContacts />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

UserDashboard.propTypes = {
  currentUser: PropTypes.object,
  tagsStats: PropTypes.object,
  getTagsStats: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    tagsStats: state.customerTagsStats,
  };
};

export default connect(mapStateToProps, {
  getTagsStats: customerTagsStats,
})(UserDashboard);

import React from "react";
import { Redirect } from "react-router-dom";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
import UserDashboard from "../pages/users/user-dashboard.js";
import Settings from "../pages/users/setings";


// Authentication related pages
import Logout from "../pages/Authentication/Logout";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import SetPassword from "../pages/Authentication/SetPassword";
import Signup from "../pages/Authentication/Signup"


// Tags
import AllTags from "../pages/tags/AllTags";
import UserTagInfo from "../pages/tags/UserTagInfo";
import Contacts from "../pages/Contacts/Contacts";
import AllOrders from "../pages/Orders/AllOrders";
import MyOrders from "../pages/Orders/MyOrders";

const userRoutes = [
  { path: "/dashboard", component: UserDashboard },
  { path: "/tags", component: (props = {}) => <AllTags showMine {...props} /> },
  {
    path: "/new-tag",
    component: (props = {}) => <UserTagInfo isNew isMine {...props} />,
  },
  {
    path: "/tag-info/:tagId",
    component: (props = {}) => <UserTagInfo isNew={false} isMine {...props} />,
  },
  {
    path: "/contacts",
    component: Contacts,
  },
  {
    path: "/my-orders",
    component: MyOrders,
  },
  {
    path: "/settings",
    component: Settings,
  },
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/dashboard" />,
  },
];

const adminRoutes = [
  { path: "/dashboard", component: Dashboard },

  // customers
  { path: "/customers", component: AllTags },

  // tags
  { path: "/tags", component: AllTags },
  {
    path: "/new-tag",
    component: (props = {}) => <UserTagInfo isNew {...props} />,
  },
  {
    path: "/tag-info/:tagId",
    component: (props = {}) => <UserTagInfo isNew={false} {...props} />,
  },
  {
    path: "/all-orders",
    component: AllOrders,
  },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/set-password/:token", component: SetPassword },
  { path: "/signup", component: Signup },
];

export { userRoutes, adminRoutes, authRoutes };

import { GET_ANALYTICS, GET_ANALYTICS_SUCCESS, GET_ANALYTICS_FAIL } from "./actionTypes";


export const getAnalytics = () => ({
  type: GET_ANALYTICS,
});

export const getAnalyticsSuccess = data => {
  return ({
    type: GET_ANALYTICS_SUCCESS,
    payload: data,
  });
};

export const getAnalyticsFail = error => ({
  type: GET_ANALYTICS_FAIL,
  payload: error,
});

import {
  GET_MY_ORDERS,
  GET_MY_ORDERS_SUCCESS,
  GET_MY_ORDERS_FAIL
} from "./actionTypes";

const initialState = {
  data: null,
  error: null,
  loading: false,
  userId: null
};

const orders = (state = initialState, action) => {
  switch (action.type) {
    case GET_MY_ORDERS:
    case GET_MY_ORDERS_SUCCESS:
    case GET_MY_ORDERS_FAIL:
      state = {
        ...state,
        ...action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default orders;

import {
  Table,
  CardTitle,
  Col,
  Card,
  CardBody,
  Row,
} from "reactstrap";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumb from '../../components/Common/Breadcrumb';
import { getMyOrders } from "../../store/actions";
import moment from "moment";
import { Link } from "react-router-dom";

const MyOrders = () => {
  // state
  const { myOrders } = useSelector((state) => state);

  console.log("my orders", myOrders)

  // dispatch
  const dispatch = useDispatch();

  // useEffect
  useEffect(() => {
    dispatch(getMyOrders());
  }, [dispatch]);
  return (
    <div className="order-container">
      <Row>
        <Col lg={12}>
          <Breadcrumb title="Bizcotap" breadcrumbItem="All Orders" />
          <Card>
            <CardBody>
              <CardTitle>
                <p className="order-title">My Orders</p>
                {myOrders?.loading ? (
                  <div
                    className="spinner-border spinner-border-sm mx-auto text-black mb-1"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>)
                  : null}
              </CardTitle>
              {myOrders?.data && <div className="table-responsive">
                <Table className="table-centered">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th scope="col">Date and Time</th>
                      <th scope="col">Id no.</th>
                      <th scope="col">Billing Name</th>
                      <th scope="col">Amount</th>
                      <th scope="col">
                        Payment Status
                      </th>
                      <th scope="col">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <>
                    {myOrders?.data?.map((order, index) => (
                      <tbody key={order?.id}>
                        <tr>
                          <th>{index + 1}</th>
                          <td>{moment(order?.created_at).format('MMMM Do YYYY, h:mm a')}</td>
                          <td>{order?.order_number}</td>
                          <td>{order?.fname} {order?.lname}</td>
                          <td>{order?.total_amount}</td>
                          <td>
                            <span className="badge badge-success text-lowercase">{order?.order_status}</span>
                          </td>
                          <td>
                            <Link to="#" className="btn btn-primary btn-sm">
                              View
                            </Link>
                          </td>
                        </tr>
                      </tbody>
                    ))}
                  </>
                </Table>
              </div>}

            </CardBody>
          </Card>
        </Col>
      </Row>

    </div>
  );
};

export default MyOrders;
import React from 'react';

import "./styles.css";

const Spinner = () => {
	return (
		<div id="preloader">
			<div id="status">
				<div className="spinner-chase">
					<div className="chase-dot">
					</div>
					<div className="chase-dot"></div>
					<div className="chase-dot"></div>
					<div className="chase-dot"></div>
					<div className="chase-dot"></div>
					<div className="chase-dot"></div>
				</div>
			</div>
		</div>
	);
};

export default Spinner;
import { GET_MY_ORDERS, GET_MY_ORDERS_SUCCESS, GET_MY_ORDERS_FAIL } from "./actionTypes";


export const getMyOrders = () => ({
  type: GET_MY_ORDERS,
  payload: {
    loading: true
  }
});

export const getMyOrdersSuccess = (data) => {
  return ({
    type: GET_MY_ORDERS_SUCCESS,
    payload: {
      data,
      loading: false,
    },
  });
};

export const getMyOrdersFail = (error) => ({
  type: GET_MY_ORDERS_FAIL,
  payload: {
    error,
    loading: false,
  },
});

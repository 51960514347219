
import { Spinner } from "reactstrap";
import {
  companyImg,
  email,
  location,
  phone,
  web,
  whatsapp,
  whiteLogo,
} from "../../assets";

import { RiArrowRightSLine } from "react-icons/ri";
import { getFieldTypeLabel } from "../../constants";

import "./styles.css";

const Profile = ({ isNew, tagInfo, isLoading, company, title, fName, lName, position, avatar, onAvatarInputChange, tagAvatarInputRef, onUploadAvatar, socialInfo }) => {
  return (
    <>
      <div className="mx-auto max-w-[560px]">
        <div className="bg-biz mx-4 bg-primary position-relative z-[-1] text-center">
          <img
            src={whiteLogo}
            alt="logo"
            width="120"
            className="mx-auto pt-5"
          />
          <input
            type="file"
            accept="image/png, image/svg, image/jpeg, image/jpg"
            className="d-none"
            id="tag_avatar_file"
            onChange={onAvatarInputChange}
            ref={tagAvatarInputRef}
            multiple={false}
            disabled={isLoading}
          />
          <div className="profile-pic">
            {avatar ? (
              <div className="profile-img tag-avatar" onClick={onUploadAvatar}>
                <img src={avatar} alt="profile" className="icon-txt" />
              </div>
            ) : (
              <div className="icon-container" onClick={onUploadAvatar}>
                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="text-dark" height="60" width="60" xmlns="http://www.w3.org/2000/svg">
                  <g id="User"><g>
                    <path d="M17.438,21.937H6.562a2.5,2.5,0,0,1-2.5-2.5V18.61c0-3.969,3.561-7.2,7.938-7.2s7.938,3.229,7.938,7.2v.827A2.5,2.5,0,0,1,17.438,21.937ZM12,12.412c-3.826,0-6.938,2.78-6.938,6.2v.827a1.5,1.5,0,0,0,1.5,1.5H17.438a1.5,1.5,0,0,0,1.5-1.5V18.61C18.938,15.192,15.826,12.412,12,12.412Z"></path><path d="M12,9.911a3.924,3.924,0,1,1,3.923-3.924A3.927,3.927,0,0,1,12,9.911Zm0-6.847a2.924,2.924,0,1,0,2.923,2.923A2.926,2.926,0,0,0,12,3.064Z">
                    </path>
                  </g></g>
                </svg>
              </div>
            )}
            {isLoading && (
              <Spinner
                size="small"
                color="success"
                style={{
                  position: "absolute",
                  top: 30,
                  right: "calc(50% - 18px)",
                }}
              />
            )}
          </div>
        </div>
        <div className="mx-4">
          <div className="text-center title-container">
            <p className=" font-bold name-title">
              {title}{" "}
              {fName}{" "}
              {lName}
            </p>
            <p className="position">
              {position}{" "}
            </p>
          </div>
          <div className="mt-4">
            <h5 className="font-bold text-[18px] text-[#143047]">
              Personal Info
            </h5>
            <div className="mt-3 space-y-1"></div>
          </div>

          <div className="space-y-6">
            {/* CompanyImg */}
            {company && <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center space-x-4">
                <div className="bg-icon-info p-[12px] rounded-lg info-icon">
                  <img src={companyImg} alt="companyImg" />
                </div>
                <div className="ml-3">
                  <p className="info-title">Company</p>
                  <p className="info-content">
                    {company}
                  </p>
                </div>
              </div>
              <div>
                <RiArrowRightSLine size={25} className="text-[#B9CEDF]" />
              </div>
            </div>}


            {/* Personal Phone Number */}
            {socialInfo?.phones?.map((item) =>

              item?.value ? (
                <div
                  key={item?.value}
                  className="d-flex align-items-center justify-content-between"
                >
                  <a
                    href={`tel:${item?.value || ""}`}
                    className="d-flex align-items-center space-x-4"
                  >
                    <div className="bg-icon-info p-[12px] rounded-lg info-icon">
                      <img src={phone} alt="phone" />
                    </div>
                    <div className="ml-3">
                      <p className="info-title">
                        Phone ({getFieldTypeLabel(item.type) || ""})
                      </p>
                      <p className="info-content">{item?.value || ""}</p>
                    </div>
                  </a>
                  <a
                    href={`https://wa.me/${String(item?.value).replace(/[^0-9]/g, "") || ""
                      }`}
                    className="info-icon"
                  >
                    <img src={whatsapp} alt="whatsapp" />
                  </a>
                </div>
              ) : null
            )}
            {/* Email Address */}
            {socialInfo?.emails?.map((item) =>
              item?.value ? (
                <div
                  key={item?.value}
                  className="d-flex align-items-center justify-content-between"
                >
                  <a
                    href={`mailto:${item?.value || ""}`}
                    className="d-flex align-items-center space-x-4"
                  >
                    <div className="bg-icon-info p-[12px] rounded-lg info-icon">
                      <img src={email} alt="email" />
                    </div>
                    <div className="ml-3">
                      <p className="info-title">
                        Email ({getFieldTypeLabel(item?.type) || ""})
                      </p>
                      <p className="info-content">{item?.value || ""}</p>
                    </div>
                  </a>
                  <a href={`mailto:${item?.value || ""}`}>
                    <RiArrowRightSLine size={25} className="text-[#B9CEDF]" />
                  </a>
                </div>
              ) : null
            )}

            {/* websites */}
            {socialInfo?.websites?.map((item) =>
              item?.value ? (
                <div
                  key={item?.value}
                  className="d-flex align-items-center justify-content-between"
                >
                  <a
                    href={item?.value || "#"}
                    className="d-flex align-items-center space-x-4"
                  >
                    <div className="bg-icon-info p-[12px] rounded-lg info-icon">
                      <img src={web} alt="web" />
                    </div>
                    <div className="ml-3">
                      <p className="info-title">
                        Website ({getFieldTypeLabel(item.type) || ""})
                      </p>
                      <p className="info-content">{item?.value || ""}</p>
                    </div>
                  </a>
                  <a href={item?.value || "#"}>
                    <RiArrowRightSLine size={25} className="text-[#B9CEDF]" />
                  </a>
                </div>
              ) : null
            )}

            {/* Addresses */}
            {socialInfo?.addresses?.map((item) =>
              item?.value ? (
                <div
                  key={item?.value}
                  className="d-flex align-items-center justify-content-between"
                >
                  <a
                    href={item?.value || "#"}
                    className="d-flex align-items-center space-x-4"
                  >
                    <div className="bg-icon-info p-[12px] rounded-lg info-icon d-flex align-items-center justify-center">
                      <img src={location} alt="web" />
                    </div>
                    <div className="ml-3">
                      <p className="info-title">
                        Addresses ({getFieldTypeLabel(item.type) || ""})
                      </p>
                      <p className="info-content">{item?.value || ""}</p>
                    </div>
                  </a>
                  <a href={"/"} className="info-icon">
                    <img src={location} alt="whatsapp" />
                  </a>
                </div>
              ) : null
            )}
          </div>
        </div>
      </div>
      {!tagInfo && !isLoading && !isNew && (
        <div className="mx-auto max-w-[560px] text-center pt-[45vh]">
          <h3 className="text-primary text-[20px] font-bold">
            We couldn't find that profile
          </h3>
          <small className="pt-3 text-[12px] text-red-900">
            This might be due to no or poor network
          </small>
        </div>
      )}
    </>
  );
};

export default Profile;

import React from "react";
import {
  Card,
  CardBody,
  Table,
  CardTitle,
  Col,
} from "reactstrap";
import { Link } from "react-router-dom";
import { getAllOrders } from "../../store/actions";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

const LatestTransaction = () => {
  // state
  const { allOrders } = useSelector((state) => state);

  // 3 recent orders
  const recentOrders = allOrders?.data?.slice(0,3)

  // dispatch
  const dispatch = useDispatch();

  // useEffect
  useEffect(() => {
    dispatch(getAllOrders());
  }, [dispatch]);

  return (
    <Col lg={12}>
      <Card>
        <CardBody>
          <CardTitle>
            <p className="order-title">Recent Orders</p>
            {allOrders?.loading ? (
              <div
                className="spinner-border spinner-border-sm mx-auto text-black mb-1"
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>)
              : null}
          </CardTitle>
          {allOrders?.data && <div className="table-responsive">
            <Table className="table-centered">
              <thead>
                <tr>
                  <th scope="col">Date and Time</th>
                  <th scope="col">Id no.</th>
                  <th scope="col">Billing Name</th>
                  <th scope="col">Amount</th>
                  <th scope="col">
                    Payment Status
                  </th>
                  <th scope="col">
                    Action
                  </th>
                </tr>
              </thead>
              <>
                {recentOrders?.map((order) => (
                  <tbody key={order?.id}>
                    <tr>
                      <td>{moment(order?.created_at).format('MMMM Do YYYY, h:mm a')}</td>
                      <td>{order?.order_number}</td>
                      <td>{order?.fname} {order?.lname}</td>
                      <td>{order?.total_amount}</td>
                      <td>
                        <span className="badge badge-success text-lowercase">{order?.order_status}</span>
                      </td>
                      <td>
                        <Link to="#" className="btn btn-primary btn-sm">
                          View
                        </Link>
                      </td>
                    </tr>
                  </tbody>
                ))}
              </>
            </Table>
          </div>}

        </CardBody>
      </Card>
    </Col>
  );
};
export default LatestTransaction;

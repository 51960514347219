import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { withResizeDetector } from "react-resize-detector";
import {
  Switch,
  BrowserRouter as Router,
  Route,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Import Routes all
import { userRoutes, authRoutes, adminRoutes } from "./routes/allRoutes";

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware";

// Layouts
import NonAuthLayout from "./layouts/NonAuthLayout";
import ClientLayout from "./layouts/ClientLayout"
import AdminLayout from "./layouts/AdminLayout"

import { getCurrentUser } from "./store/users/currentUser/actions";
import { U_ROLE_NORMAL, U_ROLE_SUPER_ADMIN } from "./constants";
import Login from "./pages/Authentication/Login";
import Spinner from "./components/Spinners/Spinner";


const App = ({width}) => {
  const [userRoles, setUserRoles] = useState();

  // dispatch
  const dispatch = useDispatch();

  // redux-saga
  const { data: currentUser, loading } = useSelector((state) => state.currentUser);

  const body = document.querySelector("body");

  width > 767 && width < 1200
      ? body.setAttribute("data-sidebar-style", "overlay")
      : width < 768
      ? body.setAttribute("data-sidebar-style", "overlay")
      : body.setAttribute("data-sidebar-style", "mini");

  useEffect(() => {
    dispatch(getCurrentUser());
  }, [dispatch]);

  useEffect(() => {
    if (currentUser?.role) {
      setUserRoles(currentUser?.role?.map?.(({ id }) => id));
    }
  }, [currentUser?.role]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <React.Fragment>
      <Router>
        <Switch>
          <>
          {authRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
            />
          ))}
          {userRoles && userRoles.includes(U_ROLE_SUPER_ADMIN)
            ? adminRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={AdminLayout}
                component={route.component}
                key={idx}
                isAuthProtected
                exact
              />
            ))
            : userRoles &&
            userRoles.includes(U_ROLE_NORMAL) &&
            userRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={ClientLayout }
                component={route.component}
                key={idx}
                isAuthProtected
                exact
              />
            ))}
          {!userRoles && (
            <Route path="/" render={(props = {}) => <Login {...props} />} />
          )}
          </>
        </Switch>
      </Router>
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any,
  currentUser: PropTypes.object,
  getCurrentUser: PropTypes.func,
};

export default withResizeDetector(App);

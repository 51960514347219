/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import moment from "moment";
import {
  Row,
  Col,
  CardBody,
  Card,
  Form,
  UncontrolledAlert as Alert,
  Button,
  Input,
  Label,
} from "reactstrap";
// import DatePicker from "react-datepicker";
import {
  customerTagInfo,
  modifyCustomerTag,
  newAvatarUpload,
  newCustomerTag,
  onNewAvatarUploadReset,
} from "../../store/actions";

import { FIELD_TYPES } from "../../constants";
import Profile from "../../components/Profile/Profile";

// date picker css
import "react-datepicker/dist/react-datepicker.css";
import Breadcrumb from "../../components/Common/Breadcrumb";

// Phone Field
const FieldPhone = ({ item, onRemove, onChange, showLabel = true }) => {
  return (
    <div className="inner-repeater tag-field-phone">
      {showLabel && <Label className="col-form-Label">Phone:</Label>}
      <table
        style={{
          width: "100%",
        }}>
        <tbody>
          <tr>
            <td>
              <div className="row mb-2">
                <div className="col-md-2">
                  <select
                    className="form-control"
                    onChange={(evt) => onChange("type", evt.target.value)}
                    value={item.type}>
                    {FIELD_TYPES.phone.map((val) => (
                      <option value={val}>{val}</option>
                    ))}
                  </select>
                </div>
                <div className="col-lg-9">
                  <Input
                    type="text"
                    className="inner form-control"
                    placeholder="Enter your phone no..."
                    onChange={(evt) => {
                      onChange("value", evt.target.value);
                    }}
                    value={item.value}
                  />
                </div>
                <div className="col-lg-1">
                  <Button
                    onClick={onRemove}
                    color="danger"
                    className="minusBtn"
                    style={{
                      width: "100%",
                    }}>
                    <i className="mdi mdi-close"></i>
                  </Button>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

// Email Field
const FieldEmail = ({ item, onRemove, onChange, showLabel = true }) => {
  return (
    <div className="inner-repeater tag-field-email">
      {showLabel && <Label className="col-form-Label">Email Address:</Label>}
      <table
        style={{
          width: "100%",
        }}>
        <tbody>
          <tr>
            <td>
              <div className="row mb-2">
                <div className="col-md-2">
                  <select
                    className="form-control"
                    onChange={(evt) => onChange("type", evt.target.value)}
                    value={item.type}>
                    {FIELD_TYPES.email.map((val) => (
                      <option value={val}>{val}</option>
                    ))}
                  </select>
                </div>
                <div className="col-lg-9">
                  <Input
                    type="email"
                    className="inner form-control"
                    placeholder="Enter your email address..."
                    onChange={(evt) => onChange("value", evt.target.value)}
                    value={item.value}
                  />
                </div>
                <div className="col-lg-1">
                  <Button
                    onClick={onRemove}
                    color="danger"
                    className="minusBtn"
                    style={{
                      width: "100%",
                    }}>
                    <i className="mdi mdi-close"></i>
                  </Button>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

// Website Field
const FieldWebsite = ({ item, onRemove, onChange, showLabel = true }) => {
  return (
    <div className="inner-repeater tag-field-website">
      {showLabel && <Label className="col-form-Label">Website/Link:</Label>}
      <table
        style={{
          width: "100%",
        }}>
        <tbody>
          <tr>
            <td>
              <div className="row mb-2">
                <div className="col-md-2">
                  <select
                    className="form-control"
                    onChange={(evt) => onChange("type", evt.target.value)}
                    value={item.type}>
                    {FIELD_TYPES.website.map((val) => (
                      <option value={val}>{val}</option>
                    ))}
                  </select>
                </div>
                <div className="col-lg-9">
                  <Input
                    type="link"
                    className="inner form-control"
                    placeholder="Enter your website/link..."
                    onChange={(evt) => onChange("value", evt.target.value)}
                    value={item.value}
                  />
                </div>
                <div className="col-lg-1">
                  <Button
                    onClick={onRemove}
                    color="danger"
                    className="minusBtn"
                    style={{
                      width: "100%",
                    }}>
                    <i className="mdi mdi-close"></i>
                  </Button>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

// Address Field
const FieldAddress = ({ item, onRemove, onChange, showLabel = true }) => {
  return (
    <div className="inner-repeater tag-field-address">
      {showLabel && <Label className="col-form-Label">Address:</Label>}
      <table
        style={{
          width: "100%",
        }}>
        <tbody>
          <tr>
            <td>
              <div className="row mb-2">
                <div className="col-md-2">
                  <select
                    className="form-control"
                    onChange={(evt) => onChange("type", evt.target.value)}
                    value={item.type}>
                    {FIELD_TYPES.address.map((val) => (
                      <option value={val}>{val}</option>
                    ))}
                  </select>
                </div>
                <div className="col-lg-9">
                  <Input
                    type="address"
                    className="inner form-control"
                    placeholder="Enter your address..."
                    onChange={(evt) => onChange("value", evt.target.value)}
                    value={item.value}
                  />
                </div>
                <div className="col-lg-1">
                  <Button
                    onClick={onRemove}
                    color="danger"
                    className="minusBtn"
                    style={{
                      width: "100%",
                    }}>
                    <i className="mdi mdi-close"></i>
                  </Button>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

// User Tag Component
const UserTagInfo = (props) => {
  const [title, setTitle] = useState("");
  const [company, setCompany] = useState("");
  const [lName, setLName] = useState("");
  const [fName, setFName] = useState("");
  const [position, setPosition] = useState("");
  const [eventType, setEventType] = useState("");
  // const [dob, setDob] = useState(moment(Date()).format("MMM Do YY"));
  const [notes, setNotes] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [avatar, setAvatar] = useState("");
  const [tag, setTag] = useState();
  const tagAvatarInputRef = useRef();

  const onSaveTagInfo = (evt) => {
    evt.preventDefault();
    if (props?.isNew) {
      const newTag = {
        tag: {
          ...tag,
          avatar: avatar,
          title: title,
          company: company,
          lname: lName,
          fname: fName,
          position: position,
          eventType: eventType,
          notes: notes,
          mine: 1,
        },
      };
      props?.createTag?.(newTag);
    } else if (!props?.isNew && props.match.params.tagId) {
      const update = {
        tag: {
          ...tag,
          avatar: avatar,
          title: title,
          company: company,
          lname: lName,
          fname: fName,
          position: position,
          eventType: eventType,
          notes: notes,
          mine: props?.isMine ? 1 : 0,
        },
      };
      props?.editTagInfo?.(props.match.params.tagId, update);
    }
  };

  const onTagFormChange = (evt) => {
    if (evt && evt?.target?.name) {
      evt?.preventDefault?.();
      const newState = {
        ...tag,
        [evt?.target?.name]: evt?.target?.value || "",
      };
      setTag(newState);
    }
  };

  const onAddNewPhone = () => {
    const newState = {
      ...tag,
      phones: [
        ...(tag?.phones || []),
        {
          type: FIELD_TYPES.phone[0],
          value: "",
        },
      ],
    };
    setTag(newState);
  };

  const onAddNewEmail = () => {
    setTag((prevState) => ({
      ...prevState,
      emails: [
        ...(prevState?.emails || []),
        {
          type: FIELD_TYPES.email[0],
          value: "",
        },
      ],
    }));
  };

  const onAddNewWebsite = () => {
    setTag((prevState) => ({
      ...prevState,
      websites: [
        ...(prevState?.websites || []),
        {
          type: FIELD_TYPES.website[0],
          value: "",
        },
      ],
    }));
  };

  const onAddNewAddress = () => {
    setTag((prevState) => ({
      ...prevState,
      addresses: [
        ...(prevState?.addresses || []),
        {
          type: FIELD_TYPES.address[0],
          value: "",
        },
      ],
    }));
  };

  const onRemovePhone = (tIdx) => {
    setTag((prevState) => ({
      ...prevState,
      phones: prevState?.phones?.filter((_item, idx) => idx !== tIdx),
    }));
  };

  const onRemoveEmail = (tIdx) => {
    setTag((prevState) => ({
      ...prevState,
      emails: prevState?.emails?.filter((_item, idx) => idx !== tIdx),
    }));
  };

  const onRemoveWebsite = (tIdx) => {
    setTag((prevState) => ({
      ...prevState,
      websites: prevState?.websites?.filter((_item, idx) => idx !== tIdx),
    }));
  };

  const onRemoveAddress = (tIdx) => {
    setTag((prevState) => ({
      ...prevState,
      addresses: prevState?.addresses?.filter((_item, idx) => idx !== tIdx),
    }));
  };

  const onPhoneChange = (type, newValue, idx) => {
    const newTag = { ...tag };
    if (!newTag?.phones?.[idx]) return;
    if (type === "type") {
      newTag.phones[idx].type = newValue;
    } else if (type === "value") {
      newTag.phones[idx].value = newValue;
    }
    setTag(newTag);
  };

  const onEmailChange = (type, newValue, idx) => {
    const newTag = { ...tag };
    if (!newTag?.emails?.[idx]) return;
    if (type === "type") {
      newTag.emails[idx].type = newValue;
    } else if (type === "value") {
      newTag.emails[idx].value = newValue;
    }
    setTag(newTag);
  };

  const onWebsiteChange = (type, newValue, idx) => {
    const newTag = { ...tag };
    if (!newTag?.websites?.[idx]) return;
    if (type === "type") {
      newTag.websites[idx].type = newValue;
    } else if (type === "value") {
      newTag.websites[idx].value = newValue;
    }
    setTag(newTag);
  };

  const onAddressChange = (type, newValue, idx) => {
    const newTag = { ...tag };
    if (!newTag?.addresses?.[idx]) return;
    if (type === "type") {
      newTag.addresses[idx].type = newValue;
    } else if (type === "value") {
      newTag.addresses[idx].value = newValue;
    }
    setTag(newTag);
  };

  const onUploadAvatar = () => {
    const input = tagAvatarInputRef.current;
    input?.click?.();
  };

  const onAvatarInputChange = async (evt) => {
    try {
      const file = evt.target.files?.[0];
      if (file) {
        // uncomment the following line to store the avatar as a URL
        props?.uploadAvatar?.(file);
        // store avatar in base64
        // const base64Img = await convertImgFileToBase64(file);
        // setAvatar(base64Img);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onResetTag = () => {
    setTag(undefined);
    setTitle("");
    setCompany("");
    setFName("");
    setLName("");
    setPosition("");
    setNotes("");
    setEventType("");
    setAvatar("");
  };

  const onScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (!props?.isNew && props?.customerTagInfo?.data) {
      setTag(props.customerTagInfo?.data?.tag_info);
      setTitle(props.customerTagInfo.data?.tag_info?.title || "");
      setAvatar(props.customerTagInfo.data?.tag_info?.avatar || "");
      setCompany(props.customerTagInfo.data?.tag_info?.company || "");
      setFName(props.customerTagInfo.data?.tag_info?.fname || "");
      setLName(props.customerTagInfo.data?.tag_info?.lname || "");
      setPosition(props.customerTagInfo.data?.tag_info?.position || "");
      setNotes(props.customerTagInfo.data?.tag_info?.notes || "");
      setEventType(props.customerTagInfo.data?.tag_info?.eventType || "");
    }
    return () => {
      onResetTag();
    };
  }, [props?.isNew, props?.customerTagInfo?.data]);

  useEffect(() => {
    onResetTag();
    if (!props?.isNew && props.match.params.tagId) {
      props.getTagInfo(props.match.params.tagId);
    }
  }, [props?.isNew, props.match.params.tagId]);

  useEffect(() => {
    onScrollToTop();
    setMessage(props.modifyCustomerTag?.message);

    return () => {
      setMessage(undefined);
    };
  }, [props.modifyCustomerTag?.message]);

  useEffect(() => {
    onScrollToTop();
    setError(props.modifyCustomerTag?.error);

    return () => {
      setError(undefined);
    };
  }, [props.modifyCustomerTag?.error]);

  useEffect(() => {
    if (props.newCustomerTag?.message) {
      onResetTag();
    }
    onScrollToTop();
    setMessage(props.newCustomerTag?.message);

    return () => {
      setMessage(undefined);
    };
  }, [props.newCustomerTag?.message]);

  useEffect(() => {
    onScrollToTop();
    setError(props.newCustomerTag?.error);

    return () => {
      setError(undefined);
    };
  }, [props.newCustomerTag?.error]);

  useEffect(() => {
    if (props.newAvatarUpload?.newAvatar) {
      setAvatar(props.newAvatarUpload?.newAvatar);
    }

    return () => {
      props.uploadAvatarReset?.();
    };
  }, [props.newAvatarUpload?.newAvatar]);

  if (props?.customerTagInfo?.loading) {
    return (
      <div className="spinner-styles">
        <div
          className="spinner-border spinner-border-sm text-light"
          role="status">
          <span className="text-center sr-only">Loading...</span>
        </div>
      </div>
    );
  }
  return (
    <div className="page-content">
      <Breadcrumb
        title="Bizcotap"
        breadcrumbItem={`${props.isNew ? "New Tag" : "Update Tag"}`}
      />
      <Row>
        <Col lg={4}>
          <Card>
            <CardBody className="p-0">
              <Profile
                isNew={props.isNew}
                isLoading={props?.newAvatarUpload?.loading}
                tagInfo={props?.customerTagInfo && props?.customerTagInfo?.data}
                socialInfo={tag}
                onUploadAvatar={onUploadAvatar}
                tagAvatarInputRef={tagAvatarInputRef}
                onAvatarInputChange={onAvatarInputChange}
                company={company}
                title={title}
                lName={lName}
                fName={fName}
                position={position}
                avatar={avatar}
                notes={notes}
                eventType={eventType}
              />
            </CardBody>
          </Card>
        </Col>
        <Col lg={8}>
          <Card>
            <CardBody>
              <>
                {error && <Alert color="warning">{error}</Alert>}
                {message && <Alert color="success">{message}</Alert>}
              </>
              <Form onSubmit={onSaveTagInfo} onChange={onTagFormChange}>
                <div className="row mb-2">
                  <div className="col-lg-2">
                    <div className="row mb-3">
                      <div className="col-lg-12">
                        <Label className="col-form-Label">Title</Label>
                        <Input
                          name="title"
                          className="form-control"
                          type="text"
                          value={title}
                          onChange={(evt) => {
                            setTitle(evt.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <div className="row mb-3">
                      <div className="col-lg-12">
                        <Label className="col-form-Label">First Name</Label>
                        <Input
                          name="fname"
                          className="form-control"
                          type="text"
                          required
                          value={fName}
                          onChange={(evt) => {
                            setFName(evt.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <div className="row mb-3">
                      <div className="col-lg-12">
                        <Label className="col-form-Label">Last Name</Label>
                        <Input
                          name="lname"
                          className="form-control"
                          type="text"
                          required
                          value={lName}
                          onChange={(evt) => {
                            setLName(evt.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-lg-4">
                    <div className="row mb-3">
                      <div className="col-lg-12">
                        <Label className="col-form-Label">Company</Label>
                        <Input
                          name="company"
                          className="form-control"
                          type="text"
                          required
                          disabled={
                            props?.isNew ||
                            props?.currentUser?.data?.role[0]?.name ===
                              "superadmin"
                              ? false
                              : true
                          }
                          value={company}
                          onChange={(evt) => {
                            setCompany(evt.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="row mb-3">
                      <div className="col-lg-12">
                        <Label className="col-form-Label">Position/title</Label>
                        <Input
                          name="position"
                          className="form-control"
                          type="text"
                          required
                          value={position}
                          onChange={(evt) => {
                            setPosition(evt.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="row mb-3">
                      <div className="col-lg-12">
                        <Label className="col-form-Label">
                          Event Type (Optional)
                        </Label>
                        {/* <InputGroup>
                          <DatePicker
                            className="form-control d-block"
                            placeholder="MM-DD-YYYY"
                            name="dob"
                            value={`${
                              props.isNew
                                ? moment(Date()).format("MMM Do YY")
                                : dob
                            }`}
                            onChange={(value) => {
                              setDob(value);
                            }}
                          />
                        </InputGroup> */}
                        <Input
                          name="eventType"
                          className="form-control"
                          type="text"
                          value={eventType}
                          onChange={(evt) => {
                            setEventType(evt.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-lg-12">
                    {tag?.phones?.map((item, idx) => (
                      <FieldPhone
                        item={item}
                        onRemove={() => onRemovePhone(idx)}
                        onChange={(t, v) => onPhoneChange(t, v, idx)}
                        showLabel={idx === 0}
                      />
                    ))}
                    <Button
                      onClick={onAddNewPhone}
                      color="success"
                      className="addBtn">
                      <i className="mdi mdi-plus"></i> Add Phone
                    </Button>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-lg-12">
                    {tag?.emails?.map((item, idx) => (
                      <FieldEmail
                        item={item}
                        onRemove={() => onRemoveEmail(idx)}
                        onChange={(t, v) => onEmailChange(t, v, idx)}
                        showLabel={idx === 0}
                      />
                    ))}
                    <Button
                      onClick={onAddNewEmail}
                      color="success"
                      className="addBtn">
                      <i className="mdi mdi-plus"></i> Add Email
                    </Button>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-lg-12">
                    {tag?.websites?.map((item, idx) => (
                      <FieldWebsite
                        item={item}
                        onRemove={() => onRemoveWebsite(idx)}
                        onChange={(t, v) => onWebsiteChange(t, v, idx)}
                        showLabel={idx === 0}
                      />
                    ))}
                    <Button
                      onClick={onAddNewWebsite}
                      color="success"
                      className="addBtn">
                      <i className="mdi mdi-plus"></i> Add Website
                    </Button>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-lg-12">
                    {tag?.addresses?.map((item, idx) => (
                      <FieldAddress
                        item={item}
                        onRemove={() => onRemoveAddress(idx)}
                        onChange={(t, v) => onAddressChange(t, v, idx)}
                        showLabel={idx === 0}
                      />
                    ))}
                    <Button
                      onClick={onAddNewAddress}
                      color="success"
                      className="addBtn">
                      <i className="mdi mdi-plus"></i> Add Address
                    </Button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="row mb-3">
                      <div className="col-lg-12">
                        <Label
                          htmlFor="notes"
                          className="col-form-Label text-left">
                          Notes
                        </Label>
                        <textarea
                          id="notes"
                          name="notes"
                          rows="4"
                          className="form-control"
                          value={notes}
                          onChange={(evt) => {
                            setNotes(evt.target.value);
                          }}></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn btn-success waves-effect waves-light float-end"
                  disabled={
                    props?.modifyCustomerTag?.loading ||
                    props?.newCustomerTag?.loading
                  }>
                  <i className="bx bx-check-double font-size-16 align-middle me-2"></i>{" "}
                  {props.customerTagInfo.loading
                    ? "loading"
                    : "Save Card Information"}
                </button>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

UserTagInfo.propTypes = {
  layout: PropTypes.any,
  isNew: PropTypes.bool,
  isMine: PropTypes.bool,
  currentUser: PropTypes.object,
  customerTagInfo: PropTypes.object,
  getTagInfo: PropTypes.func,
  editTagInfo: PropTypes.func,
  createTag: PropTypes.func,
  uploadAvatar: PropTypes.func,
  uploadAvatarReset: PropTypes.func,
};

const mapStateToProps = (state) => {
  console.log("state", state);
  return {
    layout: state.Layout,
    currentUser: state.currentUser,
    customerTagInfo: state.customerTagInfo,
    modifyCustomerTag: state.modifyCustomerTag,
    newCustomerTag: state.newCustomerTag,
    newAvatarUpload: state.newAvatarUpload,
  };
};

export default connect(mapStateToProps, {
  getTagInfo: customerTagInfo,
  editTagInfo: modifyCustomerTag,
  createTag: newCustomerTag,
  uploadAvatar: newAvatarUpload,
  uploadAvatarReset: onNewAvatarUploadReset,
})(UserTagInfo);

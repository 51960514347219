import React from "react";
import { Link } from "react-router-dom";

import logo from "../../../assets/images/logo-dark.png";

const Header = ({
	toggle,
	setToggle,
}) => {
	return (
		<div className="header d-lg-none d-block">

			<div className="header-content">
				<nav className="navbar navbar-expand">

					<div className="collapse navbar-collapse justify-content-between">
						<div className="header-left nav-header bg-white">
							<Link to="/" className="brand-logo-front">
								<img className="logo-abbr" src={logo} alt="" />
							</Link>
						</div>
						<ul className="navbar-nav header-right">
							<div className="nav-control" onClick={() => setToggle(!toggle)}>
								<div className={`hamburger ${toggle ? "is-active" : ""}`}>
									<span className="line"></span>
									<span className="line"></span>
									<span className="line"></span>
								</div>
							</div>
						</ul>
					</div>
				</nav>
			</div>
		</div>
	);
};

export default Header;

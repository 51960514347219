import { takeEvery, put, call } from "redux-saga/effects";

import { GET_MY_ORDERS } from "./actionTypes";
import { getMyOrdersSuccess, getMyOrdersFail } from "./actions";
import { getMyOrdersEndpoint } from "../../helpers/orders";


function* myOrders(action) {
  try {
    const response = yield call(getMyOrdersEndpoint);
    console.log("respo", response);
    if (response?.ok === 1) {
      yield put(getMyOrdersSuccess(response?.myorders));
    } else {
      yield put(getMyOrdersFail(response?.message));
    }
  } catch (error) {
    console.log("error", error);
    yield put(getMyOrdersFail(error?.message || error));
  }
}

function* myOrdersSaga() {
  yield takeEvery(GET_MY_ORDERS, myOrders);
}

export default myOrdersSaga;

import {
  CUSTOMER_TAGS,
  CUSTOMER_TAGS_SUCCESS,
  CUSTOMER_TAGS_FAIL,
} from "./actionTypes";

const initialState = {
  data: null,
  error: null,
  loading: false,
  message: null,
  userId: null,
};

const customerTags = (state = initialState, action) => {
  switch (action.type) {
    case CUSTOMER_TAGS:
    case CUSTOMER_TAGS_SUCCESS:
    case CUSTOMER_TAGS_FAIL:
      state = {
        ...state,
        ...action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default customerTags;

import { useState } from 'react';
import { Link } from 'react-router-dom';
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from "react-icons/md";

const SidebarMenu = ({ link }) => {
  // local state
  const [isOpen, setIsOpen] = useState(false);

  // toggle function
  const toggle = (event) => {
    event.preventDefault();
    setIsOpen(!isOpen);
  };
  return (
    <li className="dropdown-link" onClick={link.sublink && toggle}>
      <Link to={!link.sublink && link.path}>
        {link.icon}
        <span className="ml-3">{link.name}</span>
        {link.sublink && (isOpen
          ? <MdOutlineKeyboardArrowUp size={25} className="text-white float-right pt-1 c-pointer" />
          : <MdOutlineKeyboardArrowDown size={25} className="text-white float-right pt-1 c-pointer" />)}
      </Link>

      <ul className={`sub-menu ${isOpen ? "show" : ""}`}>
        {link.sublink && link.sublink.map((sub, index) => (
          <li key={index}>
            <Link to={sub.path}>{sub.name}</Link>
          </li>
        ))}
      </ul>
    </li>

  );
};

export default SidebarMenu;
import { GET_X_CONTACTS, GET_X_CONTACTS_SUCCESS, GET_X_CONTACTS_FAIL } from "./actionTypes";


export const getXContacts = (userId) => ({
  type: GET_X_CONTACTS,
  payload: {
    userId,
    loading: true
  }
});

export const getXContactsSuccess = (data) => {
  return ({
    type: GET_X_CONTACTS_SUCCESS,
    payload: {
      data,
      loading: false,
    },
  });
};

export const getXContactsFail = (error) => ({
  type: GET_X_CONTACTS_FAIL,
  payload: {
    error,
    loading: false,
  },
});

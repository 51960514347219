import React, { useEffect } from 'react';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';
import moment from 'moment';
import { getXContacts } from '../../store/actions';


const ContactCard = ({ contact }) => {
  return (
    <div className="mb-2 sh-15 sh-md-6 exchange-contact-card">
      <div className="pt-0 pb-0 h-100 card-body">
        <div className="g-0 h-100 align-content-center row">
          <div className="d-flex align-items-center mb-3 mb-md-0 h-md-100 col-md-3 col-10">
            <span className="body-link stretched-link" href="/contact/detail">{contact?.names}</span>
          </div>
          <div className="d-flex align-items-center mb-3 mb-md-0 h-md-100 col-md-3 col-10">
            <span className="body-link stretched-link" href="/contact/detail">{contact?.email}</span>
          </div>
          <div className="d-flex align-items-center mb-1 mb-md-0 text-alternate col-md-3 col-10">
            <span className="text-small">{contact?.phone_number}</span>
          </div>
          <div className="d-flex align-items-center mb-1 mb-md-0 text-alternate col-md-3 col-10">
            <span className="text-small">{moment(contact?.created_at).format('MMMM Do YYYY, h:mm:ss a')}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const RecentContacts = () => {
  // state
  const data = useSelector(state => state.contacts);

  // get only 3 contacts
  const contacts = data?.data?.[0]?.exchanged_info?.slice(0, 2);

  // dispatch
  const dispatch = useDispatch();

  // id
  const { id } = useSelector(state => state.currentUser.data);

  const getXContactsAction = useCallback((id) => dispatch(getXContacts(id)), [dispatch]);

  useEffect(() => {
    getXContactsAction(id);
  }, [getXContactsAction, id]);
  return (
    <div className="mb-5 col-xl-12">
      <div className="d-flex align-items-center justify-content-start mb-2">
        <h2 className="exchange-contact-title mt-2">Recent Contacts Exchanged</h2>
        {data?.loading && (
          <Spinner size="sm" color="primary" className="mx-2" />
        )}
      </div>
      {contacts?.map((contact) => (
        <ContactCard key={contact.id} contact={contact} />
      ))}
    </div>
  );
};

export default RecentContacts;
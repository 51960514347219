import { get } from "./api_helper";
import { GET_ANLYTICS_URL, GET_ORDERS, GET_X_CONTACTS } from "./url_helper";

// get analytics
export const getAnalytics = () =>
  get(`${GET_ANLYTICS_URL}`);


  // get orders
  export const allOrdersEndpoint = () =>
  get(`${GET_ORDERS}`);

  // get exchanged contacts
  export const xContactsEndpoint = (userId) =>
  get(`${GET_X_CONTACTS}/${userId}`); 
import {
  GET_X_CONTACTS,
  GET_X_CONTACTS_SUCCESS,
  GET_X_CONTACTS_FAIL
} from "./actionTypes";

const initialState = {
  data: null,
  error: null,
  loading: false,
  userId: null
};

const exchangedContacts = (state = initialState, action) => {
  switch (action.type) {
    case GET_X_CONTACTS:
    case GET_X_CONTACTS_SUCCESS:
    case GET_X_CONTACTS_FAIL:
      state = {
        ...state,
        ...action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default exchangedContacts;

import {
  GET_ALL_ORDERS,
  GET_ALL_ORDERS_SUCCESS,
  GET_ALL_ORDERS_FAIL
} from "./actionTypes";

const initialState = {
  data: null,
  error: null,
  loading: false,
};

const allOrders = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_ORDERS:
    case GET_ALL_ORDERS_SUCCESS:
    case GET_ALL_ORDERS_FAIL:
      state = {
        ...state,
        ...action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default allOrders;

import { call, put, takeLeading } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes";
import {
  loginError,
  loginSuccess,
  logoutError,
  logoutUserSuccess,
} from "./actions";

import { onCurrUserSuccess } from "../../users/currentUser/actions";

//Include Both Helper File with needed methods
import { postJwtLogin } from "../../../helpers/auth";
import { LS_FIELD_JWT } from "../../../constants";

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(postJwtLogin, {
      email: user.email,
      password: user.password,
    });
    if (response?.ok === 1) {
      localStorage.setItem(LS_FIELD_JWT, response?.data?.token);
      yield put(loginSuccess(response?.data?.user));
      yield put(onCurrUserSuccess(response?.data?.user));
      history?.push?.("/dashboard");
      history?.go?.();
    } else {
      yield put(loginError(response?.message || "Login failed!"));
    }
  } catch (error) {
    yield put(loginError(error?.message || error));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem(LS_FIELD_JWT);
    history.push("/login");
    yield put(logoutUserSuccess());
    history?.go?.();
  } catch (error) {
    yield put(logoutError(error?.message || error));
  }
}


function* authSaga() {
  yield takeLeading(LOGIN_USER, loginUser);
  yield takeLeading(LOGOUT_USER, logoutUser);
}

export default authSaga;

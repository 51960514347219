import { takeEvery, put, call } from "redux-saga/effects";

import { GET_X_CONTACTS } from "./actionTypes";
import { getXContactsSuccess,  getXContactsFail } from "./actions";
import { xContactsEndpoint } from "../../helpers/dashboard";


function* allExchangedContacts(action) {
  try {
    const response = yield call(xContactsEndpoint, action?.payload?.userId);
    console.log("user--", action?.payload?.userId)
    console.log("respo", response);
    if (response?.ok === 1) {
      yield put(getXContactsSuccess(response?.data));
    } else {
      yield put(getXContactsFail(response?.message));
    }
  } catch (error) {
    console.log("error", error)
    yield put(getXContactsFail(error?.message || error));
  }
}

function* exchangedContactsSaga() {
  yield takeEvery(GET_X_CONTACTS, allExchangedContacts);
}

export default exchangedContactsSaga;

/* eslint-disable jsx-a11y/role-supports-aria-props */
import React, { Component, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import MetisMenu from "metismenujs";
import { CiUser } from "react-icons/ci";

import logo from "../../../assets/images/logo-light.png";
import { useSelector } from "react-redux";
import { AiOutlineLogout, AiOutlineSetting } from "react-icons/ai";
import { LS_FIELD_JWT } from "../../../constants";


class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }

  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = () => {
  // redux
  const { data: currentUser } = useSelector(state => state.currentUser);

  console.log("user", currentUser);

  // logout
  const logoutUser = () => {
    localStorage.removeItem(LS_FIELD_JWT);
    window.location.href = "/login";
  };

  /// Open menu
  useEffect(() => {
    // sidebar open/close
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");

    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);
  }, []);

  /// Path
  var path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];


  /// Active menu
  let deshBoard = [
    "",
    "analytics",
    "campaign",
    "social-network-campaign",
    "spendings",
    "new-compaign",
  ],
    widget = ["widget-basic"];

  return (
    <div className="deznav">
      <div className="nav-header">
        <Link to="/" className="brand-logo">
          <img className="logo-abbr z-3" src={logo} alt="" />
        </Link>
      </div>
      <PerfectScrollbar className="deznav-scroll">
        <MM className="metismenu" id="menu">
          <div className="d-flex justify-content-center avatar-container">
            <div className="d-flex flex-column mb-4" data-toggle="dropdown" aria-expanded="false">
              <div className="d-flex flex-column " data-toggle="dropdown" aria-expanded="false">
                {currentUser?.avatar ? (
                  <div className="user-img mx-auto">
                    <img
                      src={currentUser?.avatar}
                      alt=""
                      className="user-avatar-styles cover-photo"
                    />
                  </div>
                ) : (
                  <div className="user-avatar-styles cover-photo bg-white d-flex align-items-center justify-content-center">
                    <CiUser size={40} className="text-dark" />
                  </div>

                )}
                <p className="text-center text-white">{currentUser?.fname} {currentUser.lname}</p>
              </div>
              <div className="d-flex justify-content-center align-item-center mx-auto">
                <li className="top-icon">
                  <Link to="/settings">
                    <AiOutlineSetting />
                  </Link>
                </li>
                <li className="top-icon">
                  <AiOutlineLogout onClick={logoutUser} />
                </li>
              </div>
            </div>
          </div>
          <li
            className={`${deshBoard.includes(path) ? "mm-active" : ""
              }`}
          >
            <NavLink
              className="has-arrow ai-icon"
              activeClassName="active"
              to="/dashboard"
              aria-expanded="false"
            >
              <i className="flaticon-381-networking"></i>
              <span className="nav-text">Dashboard</span>
            </NavLink>

          </li>
          <li className={`${widget.includes(path) ? "mm-active" : ""}`}>
            <NavLink
              to="/tags"
              className="ai-icon"
              activeClassName="active"
              aria-expanded="false"
            >
              <i className="flaticon-381-layer-1"></i>
              <span className="nav-text">My Tags</span>
            </NavLink>
          </li>
          <li className={`${widget.includes(path) ? "mm-active" : ""}`}>
            <NavLink
              to="/my-orders"
              className="ai-icon"
              activeClassName="active"
              aria-expanded="false"
            >
              <i className="flaticon-381-television"></i>
              <span className="nav-text">My Orders</span>
            </NavLink>
          </li>
          <li className={`${widget.includes(path) ? "mm-active" : ""}`}>
            <NavLink
              to="/contacts"
              className="ai-icon"
              activeClassName="active"
              aria-expanded="false"
            >
              <i className="flaticon-381-network"></i>
              <span className="nav-text">My Contacts</span>
            </NavLink>
          </li>
        </MM>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;

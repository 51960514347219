/* eslint-disable jsx-a11y/role-supports-aria-props */
import { Link } from "react-router-dom";
import { CiUser } from "react-icons/ci";

import logo from "../../../assets/images/logo-light.png";
import { useSelector } from "react-redux";
import { AiOutlineLogout, AiOutlineSetting } from "react-icons/ai";
import { LS_FIELD_JWT, adminSidebarLinks } from "../../../constants";
import SimpleBar from 'simplebar-react';
import SidebarMenu from "./SidebarMenu";

//css
import "../../../icons/scssIcons/_icons.scss";

const SideBar = (args) => {
  // redux
  const { data: currentUser } = useSelector(state => state.currentUser);

  // logout
  const logoutUser = () => {
    localStorage.removeItem(LS_FIELD_JWT);
    window.location.href = "/login";
  };

  return (
    <div className="deznav">
      <div className="nav-header">
        <Link to="/" className="brand-logo">
          <img className="logo-abbr z-3" src={logo} alt="" />
        </Link>
      </div>
      <div className="d-flex justify-content-center avatar-container">
        <div className="d-flex flex-column mb-4" data-toggle="dropdown" aria-expanded="false">
          <div className="d-flex flex-column align-items-center" data-toggle="dropdown" aria-expanded="false">
            {currentUser?.avatar ? (
              <div className="user-img mx-auto">
                <img
                  src={currentUser?.avatar}
                  alt=""
                  className="user-avatar-styles cover-photo"
                />
              </div>
            ) : (
              <div className="user-avatar-styles cover-photo bg-white d-flex align-items-center justify-content-center">
                <CiUser size={40} className="text-dark" />
              </div>
            )}
            <p className="text-center text-white">{currentUser?.fname} {currentUser.lname}</p>
          </div>
          <div className="d-flex justify-content-center align-item-center mx-auto">
            <li className="top-icon">
              <Link to="/settings">
                <AiOutlineSetting />
              </Link>
            </li>
            <li className="top-icon">
              <AiOutlineLogout onClick={logoutUser} />
            </li>
          </div>
        </div>
      </div>

      <SimpleBar className="vertical-simplebar">
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {adminSidebarLinks.map((item) => (
              <>
                <li className="menu-title">{item.title} </li>
                {item.links.map((link, index) => {
                  return (
                    <SidebarMenu link={link} key={index} />
                  );
                })}
              </>
            ))}
          </ul>
        </div>
      </SimpleBar>
    </div>
  );
};

export default SideBar;

import { Table, Spinner, CardTitle } from "reactstrap";
import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "react-avatar";

import Breadcrumb from '../../components/Common/Breadcrumb';
import { getXContacts } from "../../store/actions";

// css
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

// contact rows
const ContactsRow = ({ contact }) => {
  return (
    <tr className="table-borderless">
      <td style={{ minWidth: 100 }}>
        <Avatar name={contact?.names} size={35} round className="mr-2" />
        {contact?.names}
      </td>
      <td style={{ minWidth: 100 }}>{contact?.email}</td>
      <td style={{ minWidth: 100 }}>{contact?.phone_number}</td>
    </tr>
  );
};

// contact component
const Contacts = () => {
  const data = useSelector(state => state.contacts);

  // dispatch
  const dispatch = useDispatch();

  // id
  const { id } = useSelector(state => state.currentUser.data);

  const getXContactsAction = useCallback((id) => dispatch(getXContacts(id)), [dispatch]);

  useEffect(() => {
    getXContactsAction(id);
  }, [getXContactsAction, id]);

  return (
    <div className="contact-container">
      <Breadcrumb title="Bizcotap" breadcrumbItem="Contacts" />
      <div>
        <div className="d-flex align-items-center justify-content-start mb-2">
          <CardTitle className="ml-3 mt-4 mb-2">
            All Exchanged Contacts
            {data?.loading && (
              <Spinner size="sm" color="primary" className="mx-2 mb-1" />
            )}
          </CardTitle>
        </div>
        {data?.data &&
          data?.data[0]?.exchanged_info && (
            <div className="table-responsive">
              <Table
                id="allTags"
                className="table-centered table-nowrap"
                size="sm"
                responsive>
                <thead>
                  <tr>
                    <th>Full Name</th>
                    <th>Email</th>
                    <th>Phone Number</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.data[0]?.exchanged_info?.map((contact) => (
                    <ContactsRow key={contact.id} contact={contact} />
                  ))}
                </tbody>
              </Table>
            </div>
          )}
      </div>
    </div>
  );
};

export default Contacts;

import {
  GET_ANALYTICS,
  GET_ANALYTICS_SUCCESS,
  GET_ANALYTICS_FAIL
} from "./actionTypes";

const initialState = {
  analytics: null,
  error: null,
  loading: false,
};

const analytics = (state = initialState, action) => {
  switch (action.type) {
    case GET_ANALYTICS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_ANALYTICS_SUCCESS:
      state = {
        ...state,
        analytics: action.payload,
        loading: false,
      };
      break;
    case GET_ANALYTICS_FAIL:
      state = {
        ...state,
        error: action.payload,
        loading: false
      };
      break;
    default:
      state = {
        ...state
      };
      break;
  }
  return state;
};

export default analytics;

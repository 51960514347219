import { put, call, takeLatest } from "redux-saga/effects";

import { GET_ANALYTICS } from "./actionTypes";
import { getAnalyticsFail, getAnalyticsSuccess } from "./actions";
import { getAnalytics } from "../../../helpers/dashboard";


function* allAnalytics(action) {
  try {
    const response = yield call(getAnalytics);
    if (response?.ok === 1) {
      yield put(getAnalyticsSuccess(response?.data));
    } else {
      yield put(getAnalyticsFail(response?.error));
    }
  } catch (error) {
    yield put(getAnalyticsFail(error?.message || error));
  }
}

function* allAnalyticsSaga() {
  yield takeLatest(GET_ANALYTICS, allAnalytics);
}

export default allAnalyticsSaga;


export const adminSidebarLinks = [
  {
    title: "Analytics",
    links: [
      {
        id: 1,
        name: "Dashboard",
        path: "/dashboard",
        icon: <i className="dripicons dripicons-graph-pie"></i>,
      },
      {
        id: 2,
        name: "Orders",
        path: "/all-orders",
        icon: <i className="dripicons dripicons-shopping-bag"></i>,
      },
      {
        id: 3,
        name: "Products",
        path: "/#",
        icon: <i className="dripicons dripicons-view-thumb"></i>,
        sublink: [
          {
            name: "Add New",
            path: "/#"
          },
          {
            name: "All Products",
            path: "/#"
          },
          {
            name: "Categories",
            path: "/#"
          },
        ]
      },
      {
        id: 4,
        name: "Staff",
        path: "/#",
        icon: <i className="dripicons dripicons-user"></i>,
      },
      {
        id: 5,
        name: "Customers",
        path: "/customers",
        icon: <i className="dripicons dripicons-user-group"></i>,
      },
      {
        id: 5,
        name: "User Tags",
        path: "/#",
        icon: <i className="dripicons dripicons-tags"></i>,
        sublink: [
          {
            name: "Add New",
            path: "/new-tag"
          },
          {
            name: "All Tags",
            path: "/tags"
          },
        ]
      },
    ],
  },
  {
    title: "Store Front Settings",
    links: [
      {
        id: 6,
        name: "Home Page",
        path: "/#",
        icon: <i className="dripicons dripicons-home"></i>,
        sublink: [
          {
            name: "Clients",
            path: "/#"
          },
          {
            name: "Testimonials",
            path: "/#"
          },
          {
            name: "Trees Formular",
            path: "/#"
          },
        ]
      },
      {
        id: 7,
        name: "About Page",
        path: "/#",
        icon: <i className="dripicons dripicons-document"></i>,
        sublink: [
          {
            name: "About Us",
            path: "/#"
          },
          {
            name: "Goals",
            path: "/#"
          },
          {
            name: "Team Members",
            path: "/#"
          },
        ]
      },
      {
        id: 8,
        name: "Compantible List",
        path: "/#",
        icon: <i className="dripicons dripicons-document-edit"></i>,
      },
    ],
  },
  {
    title: "Settings",
    links: [
      {
        id: 9,
        name: "Payment Settings",
        path: "/#",
        icon: <i className="dripicons dripicons-wallet"></i>,
      },
      {
        id: 10,
        name: "User Account",
        path: "/#",
        icon: <i className="dripicons dripicons-gear"></i>,
      },

    ],
  },
];

// field constants
export const FIELD_TYPE_MAIN = "PREF";
export const FIELD_TYPE_WORK = "WORK";
export const FIELD_TYPE_HOME = "HOME";
export const FIELD_TYPE_CELL = "CELL";
export const FIELD_TYPE_FAX = "FAX";
export const FIELD_TYPE_MAILING = "MAILING";
export const FIELD_TYPE_OTHER = "OTHER";
export const FIELD_TYPES = {
  phone: [
    FIELD_TYPE_MAIN,
    FIELD_TYPE_WORK,
    FIELD_TYPE_HOME,
    FIELD_TYPE_CELL,
    FIELD_TYPE_FAX,
    FIELD_TYPE_OTHER,
  ],
  email: [FIELD_TYPE_HOME, FIELD_TYPE_WORK, FIELD_TYPE_OTHER],
  website: [FIELD_TYPE_HOME, FIELD_TYPE_WORK, FIELD_TYPE_OTHER],
  address: [
    FIELD_TYPE_HOME,
    FIELD_TYPE_WORK,
    FIELD_TYPE_MAILING,
    FIELD_TYPE_OTHER,
  ],
};

// get field lables constants
export const getFieldTypeLabel = (typeValue = FIELD_TYPE_OTHER) => {
  let label = "Other";
  switch (typeValue) {
    case FIELD_TYPE_MAIN:
      label = "Main";
      break;
    case FIELD_TYPE_WORK:
      label = "Work";
      break;
    case FIELD_TYPE_HOME:
      label = "Home";
      break;
    case FIELD_TYPE_CELL:
      label = "Cell";
      break;
    case FIELD_TYPE_FAX:
      label = "Fax";
      break;
    case FIELD_TYPE_MAILING:
      label = "Mailing";
      break;
    case FIELD_TYPE_OTHER:
      label = "Other";
      break;

    default:
      label = "Other";
      break;
  }
  return label;
};

// local storage constants
export const LS_FIELD_USER = '_BZ_u_';
export const LS_FIELD_JWT = '_BZ_ss_'; 

// roles constants 
// Super Admin
export const U_ROLE_SUPER_ADMIN = 1;
// Referral
export const U_ROLE_REFERRAL = 2;
// Business
export const U_ROLE_BUSINESS = 3;
// Normal User
export const U_ROLE_NORMAL = 4;

// timelines constants
export const TIMELINE_WEEKLY = "weekly";
export const TIMELINE_MONTHLY = "monthly";
export const TIMELINE_YEARLY = "yearly";

export const TIMELINES = [
  {
    label: "Weekly",
    value: TIMELINE_WEEKLY,
  },
  {
    label: "Monthly",
    value: TIMELINE_MONTHLY,
  },
  {
    label: "Yearly",
    value: TIMELINE_YEARLY,
  },
];

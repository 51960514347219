//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register";

//LOGIN
export const POST_FAKE_LOGIN = "/post-fake-login";
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login";
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd";
export const SOCIAL_LOGIN = "/social-login";
export const POST_JWT_LOGIN = "/api/v1/authenticate";

// FORGOT PASSWORD
export const POST_JWT_PASSWORD_FORGET = "/api/v1/forgot-password";

// SET PASSWORD
export const POST_SET_PASSWORD = "/api/v1/change-password";

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile";
export const POST_EDIT_PROFILE = "/post-fake-profile";

//PRODUCTS
export const GET_PRODUCTS = "/products";
export const GET_PRODUCTS_DETAIL = "/product";

//CALENDER
export const GET_EVENTS = "/events";
export const ADD_NEW_EVENT = "/add/event";
export const UPDATE_EVENT = "/update/event";
export const DELETE_EVENT = "/delete/event";
export const GET_CATEGORIES = "/categories";

//CHATS
export const GET_CHATS = "/chats";
export const GET_GROUPS = "/groups";
export const GET_CONTACTS = "/contacts";
export const GET_MESSAGES = "/messages";
export const ADD_MESSAGE = "/add/messages";

//CART DATA
export const GET_CART_DATA = "/cart";

//SHOPS
export const GET_SHOPS = "/shops";

//CRYPTO
export const GET_WALLET = "/wallet";
export const GET_CRYPTO_ORDERS = "/crypto/orders";

//INVOICES
export const GET_INVOICES = "/invoices";
export const GET_INVOICE_DETAIL = "/invoice";

//PROJECTS
export const GET_PROJECTS = "/projects";
export const GET_PROJECT_DETAIL = "/project";

//TASKS
export const GET_TASKS = "/tasks";

//CONTACTS
export const GET_USERS = "/users";
export const GET_USER_PROFILE = "/user";

// USERS
export const GET_CURRENT_USER = "/api/v1/me";

// TAGS
export const GET_CUSTOMERS = "/api/v1/user-tags";
export const GET_ALL_TAGS = "/api/v1/user-tags";
export const GET_CUSTOMER_TAGS = "/api/v1/user-tags";
export const GET_CUSTOMER_TAG_INFO = "/api/v1/tag-info";
export const POST_CUSTOMER_TAG = "/api/v1/user-tag";
export const PUT_CUSTOMER_TAG = "/api/v1/user-tag";
export const DEL_CUSTOMER_TAG = "/api/v1/user-tag";

// UPLOADS
export const POST_NEW_USER_AVATAR = "/api/v1/user-avatar";

// DOWNLOAD QR CODES
export const GET_QRCODE_OFFLINE = "/api/v1/qrcodes/offline";
export const GET_QRCODE_ONLINE = "/api/v1/qrcodes/online";

// STATS
export const GET_CUSTOMER_TAGS_STATS = "/api/v1/user-tags-stats/mine";

// ANALYTICS
export const GET_ANLYTICS_URL = "/api/v1/dashboard"

//ORDERS
export const GET_ORDERS = "/api/v1/get_all_orders"
export const GET_MY_ORDERS = "/api/v1/my_orders";

// EXCHANGED CONTACTS
export const GET_X_CONTACTS = "/api/v1/get_exchanged_info"


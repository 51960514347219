import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import Error from "../../components/alerts/Error";
import { darklogo } from "../../assets";
import { decodeJwtToken } from "../../helpers/decodeJwtToken";
import { LS_FIELD_JWT } from "../../constants";
import { setPasswordAC } from "../../store/actions";
import { userForgetPassword } from "../../store/actions";
import Success from "../../components/alerts/Success";


const SetPasswordPage = ({ props, history }) => {
  // local state
  const [passwordData, setPasswordData] = useState({});
  const [emailData, setEmailData] = useState({});
  const [showPassword, setShowPassword] = useState(false);

  // get token from params
  const { token } = useParams();
  localStorage.setItem(LS_FIELD_JWT, token);

  // decode token - get duration
  const decodedToken = decodeJwtToken(token);

  // get email from token
  const email = decodedToken?.data?.email;

  // token expired boolean
  const tokenExpired = decodedToken?.exp * 1000 < Date.now();

  // redux
  const dispatch = useDispatch();
  const { error, loading } = useSelector((state => state.SetPassword));
  const { forgetError, forgetSuccessMsg } = useSelector((state => state.ForgetPassword));

  // Handle Show and Hide Password
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // handle input change
  const handleChange = (e) => {
    const newPasswordData = { ...passwordData };
    newPasswordData[e.target.name] = e.target.value;
    setPasswordData(newPasswordData);
  };

  // get form
  const form = document.querySelector("form");

  // submit handler function
  const submitHandler = (e) => {
    e.preventDefault();
    if (!form.checkValidity()) {
      form.classList.add("was-validated");
    } else {
      form.classList.remove("was-validated");
      dispatch(setPasswordAC(passwordData));
      console.log("data", passwordData);
    }
  };

  // handle email input change
  const handleEmailChange = (e) => {
    const newData = { ...emailData };
    newData[e.target.name] = e.target.value;
    setEmailData(newData);
  };

  // submit handler function
  const handleResendLink = (e) => {
    e.preventDefault();
    if (!form.checkValidity()) {
      form.classList.add("was-validated");
    } else {
      form.classList.remove("was-validated");
      dispatch(userForgetPassword(emailData));
    }
  };

  return (
    <div className="d-flex align-items-center bg-primary image-backgroud" style={{ height: "100vh" }}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-5">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="text-center mx-auto logo-section">
                      <img
                        src={darklogo}
                        alt="about"
                        className="img-fluid"
                      />
                    </div>
                    <hr></hr>
                    {!tokenExpired ?
                      <div>
                        <div className="form-title mb-4 text-center">
                          <p>Set your Password</p>
                        </div>
                        <form noValidate onSubmit={(e) => submitHandler(e)}>
                          {error && <Error error={error} />}
                          <div className="form-group">
                            <label className="mb-1">
                              <strong>Email</strong>
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              name="email"
                              disabled
                              value={email}
                            />
                          </div>
                          <div className="form-group mt-4 position-relative">
                            <label className="mb-1">
                              <strong>Password</strong>
                            </label>
                            <input
                              type={showPassword ? "text" : "password"}
                              required
                              className="form-control"
                              placeholder="set password"
                              name="password"
                              onChange={handleChange}
                            />
                            <div
                              style={{
                                position: "absolute",
                                right: 30,
                                marginTop: "-40px",
                              }}
                            >
                              {showPassword ? (
                                <AiOutlineEyeInvisible
                                  size={20}
                                  className="text-muted"
                                  style={{ cursor: "pointer" }}
                                  onClick={handleShowPassword}
                                />
                              ) : (
                                <AiOutlineEye
                                  size={20}
                                  className="text-muted"
                                  style={{ cursor: "pointer" }}
                                  onClick={handleShowPassword}
                                />
                              )}
                            </div>
                            <span className="invalid-feedback">Password can't be empty</span>
                          </div>
                          <div className="mt-3">
                            <button
                              className="btn btn-primary w-100 waves-effect waves-light"
                              type="submit"
                              disabled={loading}
                            >
                              {loading ? (
                                <div
                                  className="spinner-border spinner-border-sm text-light"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              ) : (
                                "Set Password"
                              )}
                            </button>
                          </div>
                        </form>
                      </div> :
                      <div>
                        <div className="form-title mb-4 text-center">
                          <p>Write your email to receive a reset link</p>
                        </div>
                        <form noValidate onSubmit={(e) => handleResendLink(e)}>
                          {forgetError && <Error error={forgetError} />}
                          {forgetSuccessMsg && <Success message={forgetSuccessMsg} />}

                          <div className="form-group mt-4 position-relative">
                            <label className="mb-1">
                              <strong>Password</strong>
                            </label>
                            <input
                              type="email"
                              required
                              className="form-control"
                              placeholder="Email Address"
                              name="email"
                              onChange={handleEmailChange}
                            />
                            <span className="invalid-feedback">Invalid Email</span>
                          </div>
                          <div className="mt-3">
                            <button
                              className="btn btn-primary w-100 waves-effect waves-light"
                              type="submit"
                              disabled={loading}
                            >
                              {loading ? (
                                <div
                                  className="spinner-border spinner-border-sm text-light"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              ) : (
                                "Request Reset Link"
                              )}
                            </button>
                          </div>
                        </form>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetPasswordPage;


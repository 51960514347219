import { all, fork } from "redux-saga/effects";

//public
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import SetPasswordSaga from "./auth/setpassword/saga";
import LayoutSaga from "./layout/saga";
import exchangedContactsSaga from "./contacts/saga";
import allOrdersSaga from "./dashboard/allOrders/saga";
import myOrdersSaga from "./orders/saga";
import currentUserSaga from "./users/currentUser/saga";
import customersSaga from "./users/customers/saga";
import allTagsSaga from "./tags/allTags/saga";
import customerTagsSaga from "./tags/customerTags/saga";
import customerTagInfoSaga from "./tags/customerTagInfo/saga";
import newCustomerTagSaga from "./tags/newCustomerTag/saga";
import modifyCustomerTagSaga from "./tags/modifyCustomerTag/saga";
import removeCustomerTagSaga from "./tags/removeCustomerTag/saga";
import newAvatarUploadSaga from "./uploads/newAvatarUpload/saga";
import customerTagsStatsSaga from "./tags/customerTagsStats/saga";
import analyticsSaga from "./dashboard/analytics/saga";

export default function* rootSaga() {
  yield all([
    //public
    fork(AuthSaga),
    fork(SetPasswordSaga),
    ForgetSaga(),
    fork(LayoutSaga),
    fork(exchangedContactsSaga),
    fork(allOrdersSaga),
    fork(myOrdersSaga),
    fork(currentUserSaga),
    fork(customersSaga),
    fork(allTagsSaga),
    fork(customerTagsSaga),
    fork(customerTagInfoSaga),
    fork(newCustomerTagSaga),
    fork(modifyCustomerTagSaga),
    fork(removeCustomerTagSaga),
    fork(newAvatarUploadSaga),
    fork(customerTagsStatsSaga),
    fork(analyticsSaga)
  ]);
}
